@import "mixins"
.tournamentItem
  height: 41px
  @include flex
  justify-content: space-between
  color: #fff
  &:not(:first-of-type)
    background-image: linear-gradient(90deg,var(--sport_separator),rgba(43, 54, 71, 0.4) 75%,transparent 75%,transparent 100%)
    background-size: 2px 1px
    background-repeat: repeat-x
    background-position: top left

  &:last-of-type
    height: calc(41px - var(--live_categoryItemMargin))
    padding-top: var(--live_categoryItemMargin)

  > span
    @include ellipsis
    line-height: 14px
    font-size: 13px
